:root {
  --mslb-bg-color: #fff;
  --mslb-font-color: #000;
  --mslb-border-color: #e0e0e0;
  --mslb-btn-font-color: #fff;
  --mslb-btn-bg-add-color: #00bfa5;
  --mslb-btn-bg-remove-color: #ff5252;
  --mslb-item-hover-bg-color: #f5f5f5;
}

.root {
  background-color: var(--mslb-bg-color);
  display: flex;
  width: 100%;
  flex-direction: row;
  border: 1px solid var(--mslb-border-color);
  position: relative;
  color: var(--mslb-font-color);
  > div:first-child {
    border-right: 1px solid var(--mslb-border-color) !important;
  }
}
